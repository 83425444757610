import { RosterYear } from "./roster.model";

//The imagePath isn't used, it's legacy code and doesn't need to be there
//Further version of the website can remove it from the roster.model.ts

export const ROSTER_DATA: RosterYear[] = [
  {
    year: 2023,
    imagePath: "./assets/team-photo-2023.jpg",
    players: [
        {
        number: 0,
        name: "Eli Artemakis*",
        year: "Junior",
        },
        {
        number: 2,
        name: "Griffin Kelley",
        year: "Freshman",
        },
        {
        number: 3,
        name: "Dean Gottlieb",
        year: "Freshman",
        },
        {
        number: 4,
        name: "Kai Bowen",
        year: "Sophomore",
        },
        {
        number: 7,
        name: "Tim Sheehan",
        year: "Junior",
        },
        {
        number: 8,
        name: 'Patrick "Mark" Driscoll*',
        year: "Senior",
        },
        {
        number: 9,
        name: "Kai Tang",
        year: "Sophomore",
        },
        {
        number: 10,
        name: "Kevin Zhang",
        year: "Grad",
        },
        {
        number: 13,
        name: "JT Kirages",
        year: "Senior",
        },
        {
        number: 16,
        name: "Andy Kennedy",
        year: "Senior",
        },
        {
        number: 17,
        name: "Will Voss",
        year: "Freshman",
        },
        {
        number: 19,
        name: "Dave Kiaupa",
        year: "Senior",
        },
        {
        number: 20,
        name: "Evan Driscoll",
        year: "Junior",
        },
        {
        number: 21,
        name: "Alex Zheng",
        year: "Grad",
        },
        {
        number: 22,
        name: "Ryan Roach",
        year: "Grad",
        },
        {
        number: 23,
        name: "Maximus Nelson",
        year: "Senior",
        },
        {
        number: 25,
        name: "Simon Perez",
        year: "Freshman",
        },
        {
        number: 24,
        name: "Kevin Lui",
        year: "Sophomore",
        },
        {
        number: 27,
        name: "Jack Joshi",
        year: "Senior",
        },
        {
        number: 29,
        name: "Sean Hickey",
        year: "Freshman",
        },
        {
        number: 30,
        name: "Daniel Sawai",
        year: "Sophomore",
        },
        {
        number: 33,
        name: "Trevor Scott",
        year: "Sophomore",
        },
        {
        number: 40,
        name: "Emil Weisfield",
        year: "Sophomore",
        },
        {
        number: 44,
        name: "Evan Benjamin",
        year: "Junior",
        },
        {
        number: 49,
        name: "Nathan Zock",
        year: "Sophomore",
        },
        {
        number: 51,
        name: "Michael Iarrapino",
        year: "Sophomore",
        },
        {
        number: 61,
        name: "Patrick Leah",
        year: "Senior",
        },
        {
        number: 70,
        name: "Kevin Zhou*",
        year: "Junior",
        },
        {
        number: 75,
        name: "Matt Rylander",
        year: "Sophomore",
        },
        {
        number: 86,
        name: "Kevin Tan",
        year: "Grad",
        },
        {
        number: undefined,
        name: "Brad Bollinger",
        year: "Coach",
        },
        {
        number: undefined,
        name: "Eric Badger",
        year: "Coach",
        },
    ]
    },
  {
    year: 2022,
    imagePath: "./assets/team-photo-2022.jpg",
    players: [
      {
        number: 0,
        name: "Eli Artemakis",
        year: "Sophomore",
      },
      {
        number: 2,
        name: "Tyler Smiles*",
        year: "Senior",
      },
      {
        number: 4,
        name: "Kai Bowen",
        year: "Freshman",
      },
      {
        number: 7,
        name: "Tim Sheehan",
        year: "Sophomore",
      },
      {
        number: 8,
        name: 'Patrick "Mark" Driscoll*',
        year: "Junior",
      },
      {
        number: 9,
        name: "Kai Tang",
        year: "Sophomore",
      },
      {
        number: 11,
        name: "Joey Kennedy*",
        year: "Senior",
      },
      {
        number: 13,
        name: "JT Kirages",
        year: "Junior",
      },
      {
        number: 16,
        name: "Andy Kennedy",
        year: "Junior",
      },
      {
        number: 19,
        name: "Dave Kiaupa",
        year: "Junior",
      },
      {
        number: 20,
        name: "Evan Driscoll",
        year: "Sophomore",
      },
      {
        number: 25,
        name: "Simon Perez",
        year: "Freshman",
      },
      {
        number: 27,
        name: "Jack Joshi",
        year: "Junior",
      },
      {
        number: 29,
        name: "Daniel Sawai",
        year: "Freshman",
      },
      {
        number: 33,
        name: "Trevor Scott",
        year: "Sophomore",
      },
      {
        number: 40,
        name: "Emil Weisfield",
        year: "Freshman",
      },
      {
        number: 43,
        name: "David Yao",
        year: "Grad",
      },
      {
        number: 49,
        name: "Nathan Zock",
        year: "Freshman",
      },
      {
        number: 51,
        name: "Michael Iarrapino",
        year: "Freshman",
      },
      {
        number: 52,
        name: "John Wolff",
        year: "Grad",
      },
      {
        number: 70,
        name: "Kevin Zhou",
        year: "Junior",
      },
      {
        number: 87,
        name: "Michael Musial",
        year: "Senior",
      },
      {
        number: undefined,
        name: "Brad Bollinger",
        year: "Coach",
      },
      {
        number: undefined,
        name: "Eric Badger",
        year: "Coach",
      },
    ]
  },
  {
    year: 2021,
    imagePath: "./assets/team-photo-2021.jpg",
    players: [
      {
        number: 0,
        name: "Gregory Schwarz",
        year: "Grad*",
      },
      {
        number: 1,
        name: "Drake Hill",
        year: "Grad*",
      },
      {
        number: 2,
        name: "Tyler Smiles",
        year: "Senior",
      },
      {
        number: 4,
        name: "Asa Bowen",
        year: "Grad*",
      },
      {
        number: 7,
        name: "Alex Liu",
        year: "Grad*",
      },
      {
        number: 8,
        name: 'Patrick "Mark" Driscoll',
        year: "Junior",
      },
      {
        number: 9,
        name: "Kai Tang",
        year: "Sophomore",
      },
      {
        number: 11,
        name: "Joey Kennedy*",
        year: "Senior",
      },
      {
        number: 13,
        name: "Reid Martin*",
        year: "Grad*",
      },
      {
        number: 14,
        name: "Aidan Levin",
        year: "Grad*",
      },
      {
        number: 16,
        name: "Andy Kennedy",
        year: "Junior",
      },
      {
        number: 21,
        name: "Eli Artemakis",
        year: "Sophomore",
      },
      {
        number: 22,
        name: "Lucas Gough",
        year: "Grad*",
      },
      {
        number: 24,
        name: "Arjun Tripathy",
        year: "Grad*",
      },
      {
        number: 25,
        name: "Simon Perez",
        year: "Freshman",
      },
      {
        number: 27,
        name: "Jack Joshi",
        year: "Junior",
      },
      {
        number: 33,
        name: "Trevor Scott",
        year: "Sophomore",
      },
      {
        number: 38,
        name: "Gavin Lindsay",
        year: "Grad",
      },
      {
        number: 40,
        name: "Rishav Rout",
        year: "Grad*",
      },
      {
        number: 42,
        name: "Kastan Day",
        year: "Grad",
      },
      {
        number: 52,
        name: "John Wolff",
        year: "Grad",
      },
      {
        number: 70,
        name: "Kevin Zhou",
        year: "Junior",
      },
      {
        number: 74,
        name: "Anthony Ruzzo*",
        year: "Grad*",
      },
      {
        number: 82,
        name: "Brandon Carrel",
        year: "Grad*",
      },
      {
        number: undefined,
        name: "Brad Bollinger",
        year: "Coach",
      },
      {
        number: undefined,
        name: "Eric Badger",
        year: "Coach",
      },
      {
        number: undefined,
        name: "* on year indicates extended eligibility for Fall 2021 Season",
        year: "",
      },
    ],
  },
  {
    year: 2020,
    imagePath: "./assets/team-photo-2020.jpg",
    players: [
      {
        number: 0,
        name: "Gregory Schwarz",
        year: "Grad",
      },
      {
        number: 1,
        name: "Drake Hill",
        year: "Senior",
      },
      {
        number: 2,
        name: "Tyler Smiles",
        year: "Sophomore",
      },
      {
        number: 3,
        name: "Christopher Nguyen",
        year: "Sophomore",
      },
      {
        number: 4,
        name: "Asa Bowen",
        year: "Junior",
      },
      {
        number: 7,
        name: "Alex Liu",
        year: "Junior",
      },
      {
        number: 11,
        name: "Joey Kennedy*",
        year: "Sophomore",
      },
      {
        number: 13,
        name: "Reid Martin*",
        year: "Junior",
      },
      {
        number: 14,
        name: "Aidan Levin",
        year: "Junior",
      },
      {
        number: 15,
        name: "Arthur Albarran",
        year: "Sophomore",
      },
      {
        number: 18,
        name: "Nelson Perumpel",
        year: "Junior",
      },
      {
        number: 20,
        name: "Patrick Driscoll",
        year: "Freshman",
      },
      {
        number: 22,
        name: "Lucas Gough",
        year: "Senior",
      },
      {
        number: 24,
        name: "Arjun Tripathy",
        year: "Sophomore",
      },
      {
        number: 27,
        name: "Jack Joshi",
        year: "Freshman",
      },
      {
        number: 28,
        name: "Andy Kennedy",
        year: "Freshman",
      },
      {
        number: 40,
        name: "Rishav Rout",
        year: "Junior",
      },
      {
        number: 42,
        name: "Maanu Grover",
        year: "Junior",
      },
      {
        number: 70,
        name: "Kevin Zhou",
        year: "Freshman",
      },
      {
        number: 72,
        name: "Derek Peterson",
        year: "Junior",
      },
      {
        number: 74,
        name: "Anthony Ruzzo*",
        year: "Junior",
      },
      {
        number: 82,
        name: "Brandon Carrel",
        year: "Junior",
      },
      {
        number: undefined,
        name: "Daniel McDonald",
        year: "Coach",
      },
    ],
  },
  {
    year: 2019,
    imagePath: "./assets/team-photo-2019.jpg",
    players: [
      {
        number: 0,
        name: "Gregory Schwarz",
        year: "Grad",
      },
      {
        number: 1,
        name: "Drake Hill",
        year: "Junior",
      },
      {
        number: 2,
        name: "Ben Sabourin*",
        year: "Senior",
      },
      {
        number: 4,
        name: "Asa Bowen",
        year: "Sophomore",
      },
      {
        number: 6,
        name: "Jacob Cuthbert",
        year: "Senior",
      },
      {
        number: 7,
        name: "Alex Liu",
        year: "Sophomore",
      },
      {
        number: 8,
        name: "Devin Kelly",
        year: "Senior",
      },
      {
        number: 13,
        name: "Reid Martin*",
        year: "Sophomore",
      },
      {
        number: 14,
        name: "Aidan Levin",
        year: "Sophomore",
      },
      {
        number: 19,
        name: "Joey Kennedy",
        year: "Freshman",
      },
      {
        number: 20,
        name: "Ryan Kantor",
        year: "Senior",
      },
      {
        number: 21,
        name: "Ben Lee",
        year: "Junior",
      },
      {
        number: 22,
        name: "Lucas Gough",
        year: "Junior",
      },
      {
        number: 24,
        name: "Josh Deeman*",
        year: "Senior",
      },
      {
        number: 31,
        name: "Frank Dore",
        year: "Senior",
      },
      {
        number: 32,
        name: "Daman Mulye",
        year: "Freshman",
      },
      {
        number: 35,
        name: "Cameron Hartman",
        year: "Senior",
      },
      {
        number: 38,
        name: "John Kulikowski",
        year: "Senior",
      },
      {
        number: 40,
        name: "Rishav Rout",
        year: "Sophomore",
      },
      {
        number: 46,
        name: "Nate Willis",
        year: "Grad",
      },
      {
        number: 74,
        name: "Anthony Ruzzo",
        year: "Sophomore",
      },
      {
        number: 82,
        name: "Brandon Carrel",
        year: "Sophomore",
      },
      {
        number: undefined,
        name: "Daniel McDonald",
        year: "Coach",
      },
    ],
  },
  {
    year: 2018,
    imagePath: "./assets/team-photo-2018.jpg",
    players: [
      {
        number: 1,
        name: "Drake Hill",
        year: "Sophomore",
      },
      {
        number: 2,
        name: `Ben 'Beef' Sabourin`,
        year: "Junior",
      },
      {
        number: 4,
        name: "Asa Bowen",
        year: "Freshman",
      },
      {
        number: 6,
        name: "Jacob Cuthbert",
        year: "Junior",
      },
      {
        number: 7,
        name: "Kevin Lin",
        year: "Senior",
      },
      {
        number: 8,
        name: "Devin Kelly",
        year: "Junior",
      },
      {
        number: 13,
        name: "Walter Jaskowiak",
        year: "Junior",
      },
      {
        number: 19,
        name: "Jacob Komenda*",
        year: "Senior",
      },
      {
        number: 20,
        name: "Ryan Kantor",
        year: "Junior",
      },
      {
        number: 23,
        name: "Hector Moreno",
        year: "Senior",
      },
      {
        number: 24,
        name: "Josh Deeman*",
        year: "Junior",
      },
      {
        number: 28,
        name: "Frank Dore",
        year: "Junior",
      },
      {
        number: 35,
        name: "Cameron Hartman",
        year: "Junior",
      },
      {
        number: 36,
        name: "Dalton Chaffee",
        year: "Grad",
      },
      {
        number: 38,
        name: "John Kulikowski",
        year: "Junior",
      },
      {
        number: 47,
        name: "Grant Whitman",
        year: "Senior",
      },
      {
        number: 55,
        name: "Murray Chen",
        year: "Senior",
      },
      {
        number: 74,
        name: "Anthony Ruzzo",
        year: "Freshman",
      },
      {
        number: 89,
        name: "Reid Martin",
        year: "Freshman",
      },
      {
        number: 96,
        name: "Dirk Husemann",
        year: "Senior",
      },
      {
        number: undefined,
        name: "Daniel McDonald",
        year: "Coach",
      },
    ],
  },
  {
    year: 2017,
    imagePath: "./assets/team-photo-2017.jpg",
    players: [
      {
        number: 2,
        name: `Ben 'Beef' Sabourin`,
        year: "Sophomore",
      },
      {
        number: 3,
        name: "Cody Gray",
        year: "Grad",
      },
      {
        number: 6,
        name: "Jacob Cuthbert",
        year: "Sophomore",
      },
      {
        number: 7,
        name: "Kevin Lin",
        year: "Junior",
      },
      {
        number: 8,
        name: "Devin Kelly",
        year: "Sophomore",
      },
      {
        number: 13,
        name: "Walter Jaskowiak",
        year: "Sophomore",
      },
      {
        number: 18,
        name: "Hector Moreno",
        year: "Junior",
      },
      {
        number: 19,
        name: "Jacob Komenda*",
        year: "Junior",
      },
      {
        number: 23,
        name: "Jarred Elliott",
        year: "Senior",
      },
      {
        number: 24,
        name: "Josh Deeman",
        year: "Sophomore",
      },
      {
        number: 28,
        name: "Frank Dore",
        year: "Sophomore",
      },
      {
        number: 29,
        name: "Ben Foster*",
        year: "Senior",
      },
      {
        number: 33,
        name: "Jacob Trueb",
        year: "Junior",
      },
      {
        number: 35,
        name: "Cameron Hartman",
        year: "Sophomore",
      },
      {
        number: 38,
        name: "John Kulikowski",
        year: "Sophomore",
      },
      {
        number: 47,
        name: "Grant Whitman",
        year: "Junior",
      },
      {
        number: 81,
        name: `Chris 'Books' Buchta*`,
        year: "Senior",
      },
      {
        number: 95,
        name: "Tat Shing",
        year: "Junior",
      },
      {
        number: undefined,
        name: "Erik Huemiller",
        year: "Coach",
      },
    ],
  },
  {
    year: 2016,
    imagePath: "./assets/team-photo-2016.jpg",
    players: [
      {
        number: 1,
        name: "Nick Prozorovsky*",
        year: "Senior",
      },
      {
        number: 2,
        name: "Ben Sabourin",
        year: "Freshman",
      },
      {
        number: 3,
        name: "Cody Gray",
        year: "Grad",
      },
      {
        number: 4,
        name: "Michael Cosmos",
        year: "Senior",
      },
      {
        number: 7,
        name: "Kevin Lin",
        year: "Sophomore",
      },
      {
        number: 8,
        name: "Johnny Saniat",
        year: "Senior",
      },
      {
        number: 10,
        name: "Jacob Komenda",
        year: "Sophomore",
      },
      {
        number: 12,
        name: "Kan Zi Yang",
        year: "Senior",
      },
      {
        number: 13,
        name: "Mark Peterson",
        year: "Senior",
      },
      {
        number: 17,
        name: "Prayag Patel*",
        year: "Senior",
      },
      {
        number: 18,
        name: "Alex Guy",
        year: "Senior",
      },
      {
        number: 23,
        name: "Jarred Elliott",
        year: "Junior",
      },
      {
        number: 24,
        name: "Josh Deeman",
        year: "Freshman",
      },
      {
        number: 27,
        name: "Wilson Wang",
        year: "Senior",
      },
      {
        number: 29,
        name: "Ben Foster",
        year: "Junior",
      },
      {
        number: 37,
        name: "Walter Jaskowiak",
        year: "Freshman",
      },
      {
        number: 38,
        name: "Jacob Trueb",
        year: "Sophomore",
      },
      {
        number: 41,
        name: "Devin Kelly",
        year: "Freshman",
      },
      {
        number: 44,
        name: "Ryan Kantor",
        year: "Freshman",
      },
      {
        number: 48,
        name: "Hector Moreno",
        year: "Sophomore",
      },
      {
        number: 71,
        name: "Bobby Gazdziak",
        year: "Senior",
      },
      {
        number: 81,
        name: "Chris Buchta*",
        year: "Junior",
      },
      {
        number: 88,
        name: "Tim Chu",
        year: "Senior",
      },
      {
        number: 95,
        name: "Tat Shing",
        year: "Sophomore",
      },
      {
        number: 97,
        name: "Jacob Cuthbert",
        year: "Freshman",
      },
      {
        number: undefined,
        name: "Matt West",
        year: "Assistant Coach",
      },
      {
        number: undefined,
        name: "Erik Huemiller",
        year: "Coach",
      },
    ],
  },
  {
    year: 2015,
    imagePath: "./assets/team-photo-2015.jpg",
    players: [
      {
        number: 0,
        name: "Max Willis",
        year: "Senior",
      },
      {
        number: 1,
        name: "Nick Prozorovsky*",
        year: "Junior",
      },
      {
        number: 4,
        name: "Michael Pohling",
        year: "Senior",
      },
      {
        number: 7,
        name: "Matt West",
        year: "Grad",
      },
      {
        number: 8,
        name: "John Saniat*",
        year: "Junior",
      },
      {
        number: 10,
        name: "Joe Moyles",
        year: "Senior",
      },
      {
        number: 11,
        name: "Jon Mckoy",
        year: "Senior",
      },
      {
        number: 12,
        name: "Kan Zi Yang",
        year: "Junior",
      },
      {
        number: 13,
        name: "Mark Peterson",
        year: "Junior",
      },
      {
        number: 17,
        name: "Prayag Patel",
        year: "Junior",
      },
      {
        number: 18,
        name: "Eric Bultman",
        year: "Senior",
      },
      {
        number: 21,
        name: "Jarred Elliott",
        year: "Sophomore",
      },
      {
        number: 23,
        name: "Tyler DeNooyer",
        year: "Grad",
      },
      {
        number: 27,
        name: "Wilson Wang",
        year: "Junior",
      },
      {
        number: 29,
        name: "Ben Foster",
        year: "Sophomore",
      },
      {
        number: 35,
        name: "Marty Markenson*",
        year: "Senior",
      },
      {
        number: 38,
        name: "Grant Hallan",
        year: "Senior",
      },
      {
        number: 46,
        name: "Kevin Birchok",
        year: "Senior",
      },
      {
        number: 69,
        name: "Jacob Komenda",
        year: "Freshman",
      },
      {
        number: 75,
        name: "Eric Badger",
        year: "Grad",
      },
      {
        number: 81,
        name: "Chris Buchta",
        year: "Sophomore",
      },
      {
        number: 97,
        name: "Kevin Lin",
        year: "Freshman",
      },
      {
        number: undefined,
        name: "Matt Stupca",
        year: "Coach",
      },
    ],
  },
  {
    year: 2014,
    imagePath: "./assets/team-photo-2014.jpg",
    players: [
      {
        number: 0,
        name: "Max Willis",
        year: "Junior",
      },
      {
        number: 1,
        name: "Nick Prozorovsky",
        year: "Sophomore",
      },
      {
        number: 3,
        name: "Sean Hill",
        year: "Senior",
      },
      {
        number: 4,
        name: "Michael Pohling",
        year: "Junior",
      },
      {
        number: 6,
        name: "Nick Oo",
        year: "Junior",
      },
      {
        number: 8,
        name: "Johnny Saniat",
        year: "Sophomore",
      },
      {
        number: 10,
        name: "Joe Moyles",
        year: "Junior",
      },
      {
        number: 11,
        name: "Jon Mckoy",
        year: "Junior",
      },
      {
        number: 12,
        name: "Kan Zi Yang",
        year: "Sophomore",
      },
      {
        number: 17,
        name: "Prayag Patel",
        year: "Sophomore",
      },
      {
        number: 18,
        name: "Eric Bultman*",
        year: "Junior",
      },
      {
        number: 21,
        name: "Chris Buchta",
        year: "Freshman",
      },
      {
        number: 22,
        name: "Brian Rathunde",
        year: "Senior",
      },
      {
        number: 23,
        name: "Greg Hill",
        year: "Senior",
      },
      {
        number: 24,
        name: "Jim Riddle",
        year: "Senior",
      },
      {
        number: 27,
        name: "Wilson Wang",
        year: "Sophomore",
      },
      {
        number: 30,
        name: "Steve Brand",
        year: "Grad",
      },
      {
        number: 35,
        name: "Marty Markenson*",
        year: "Junior",
      },
      {
        number: 38,
        name: "Grant Hallan",
        year: "Junior",
      },
      {
        number: 39,
        name: "Ross Tuttle",
        year: "Senior",
      },
      {
        number: 44,
        name: "Michael Cosmos",
        year: "Sophomore",
      },
      {
        number: 46,
        name: "Kevin Birchok",
        year: "Junior",
      },
      {
        number: 64,
        name: "Neal Phelps*",
        year: "Grad",
      },
      {
        number: 69,
        name: "Tyler DeNooyer",
        year: "Grad",
      },
      {
        number: 81,
        name: "Arthur Ding",
        year: "Sophomore",
      },
      {
        number: undefined,
        name: "Walden Nelson",
        year: "Coach",
      },
    ],
  },
  {
    year: 2013,
    imagePath: "./assets/team-photo-2013.jpg",
    players: [
      {
        number: 1,
        name: "Nick Prozorovsky",
        year: "Freshman",
      },
      {
        number: 3,
        name: "Sean Hill",
        year: "Junior",
      },
      {
        number: 4,
        name: "Rob Dreier",
        year: "Senior",
      },
      {
        number: 6,
        name: "Brad Bolliger",
        year: "Senior",
      },
      {
        number: 9,
        name: "Preston Goulson",
        year: "Senior",
      },
      {
        number: 11,
        name: "Jon Mckoy",
        year: "Sophomore",
      },
      {
        number: 12,
        name: "Derek Yan",
        year: "Freshman",
      },
      {
        number: 18,
        name: "Eric Bultman",
        year: "Sophomore",
      },
      {
        number: 20,
        name: "Tyler Anderson",
        year: "Senior",
      },
      {
        number: 22,
        name: "Brian Rathunde",
        year: "Junior",
      },
      {
        number: 23,
        name: "Ryan Smith*",
        year: "Grad",
      },
      {
        number: 27,
        name: "Wilson Wang",
        year: "Freshman",
      },
      {
        number: 30,
        name: "Colin Reid*",
        year: "Senior",
      },
      {
        number: 34,
        name: "Michael Pohling",
        year: "Sophomore",
      },
      {
        number: 35,
        name: "Marty Markenson",
        year: "Sophomore",
      },
      {
        number: 39,
        name: "Ross Tuttle",
        year: "Junior",
      },
      {
        number: 44,
        name: "Nate Gibbons*",
        year: "Senior",
      },
      {
        number: 45,
        name: "Greg Hill",
        year: "Junior",
      },
      {
        number: 64,
        name: "Neal Phelps*",
        year: "Grad",
      },
      {
        number: 65,
        name: "Michael Cosmos",
        year: "Freshman",
      },
      {
        number: 87,
        name: "Adam Wright",
        year: "Senior",
      },
      {
        number: 99,
        name: "Johnny Saniat",
        year: "Junior",
      },
      {
        number: undefined,
        name: "Walden Nelson",
        year: "Coach",
      },
    ],
  },
  {
    year: 2011,
    imagePath: "./assets/team-photo-2011.jpg",
    players: [
      {
        number: 2,
        name: "Joe Sidrys",
        year: "Senior",
      },
      {
        number: 5,
        name: "Jack Krieger",
        year: "Junior",
      },
      {
        number: 8,
        name: "Kyle McKinley",
        year: "Grad",
      },
      {
        number: 13,
        name: "Tom Rudwick",
        year: "Senior",
      },
      {
        number: 14,
        name: "Austin Lien",
        year: "Senior",
      },
      {
        number: 15,
        name: "Alex Komisarz",
        year: "Senior",
      },
      {
        number: 16,
        name: "Andy Kilinskis",
        year: "Junior",
      },
      {
        number: 18,
        name: "Kurt Zoellick",
        year: "Grad",
      },
      {
        number: 22,
        name: "Brian Chrysler",
        year: "Grad",
      },
      {
        number: 23,
        name: "Ryan Smith",
        year: "Grad",
      },
      {
        number: 25,
        name: "Mark Hirschman",
        year: "Senior",
      },
      {
        number: 27,
        name: "Chris Hidaka",
        year: "Grad",
      },
      {
        number: 29,
        name: "Matt Kurley",
        year: "Grad",
      },
      {
        number: 32,
        name: "Chris Kvistad",
        year: "Junior",
      },
      {
        number: 33,
        name: "Don McDonald",
        year: "Grad",
      },
      {
        number: 36,
        name: "Brian Pierce",
        year: "Junior",
      },
      {
        number: 47,
        name: "Jon Hatcher",
        year: "Senior",
      },
      {
        number: 61,
        name: "Brian Phelan",
        year: "Grad",
      },
      {
        number: 64,
        name: "Neal Phelps*",
        year: "Senior",
      },
      {
        number: 80,
        name: "Jon Ogrodnik",
        year: "Grad",
      },
      {
        number: 81,
        name: "Suleyman Ahmad",
        year: "Senior",
      },
      {
        number: 84,
        name: `Charlie O'Brien`,
        year: "Grad",
      },
      {
        number: 87,
        name: "Adam Wright",
        year: "Junior",
      },
      {
        number: 88,
        name: "Kevin Bruns",
        year: "Junior",
      },
      {
        number: 90,
        name: "Zachary Frantz",
        year: "Junior",
      },
      {
        number: undefined,
        name: "Walden Nelson",
        year: "Coach",
      },
    ],
  },
];
