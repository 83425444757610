import { TournamentYear } from "./tournament.model";
//TODO: If the namelink is empty, then don't show a link to be able to be clicked

export const TOURNAMENTS_DATA: TournamentYear[] = [
  {
    year: 2023,
    tournaments: [
        {
        season: "Fall",
        nameLabel: "Skip To The Lou",
        nameLink: "",
        when: "Sept. 24-25",
        location: "St. Louis, MI",
        result: "-"
        },
        {
        season: "Fall",
        nameLabel: "No Wisconsequences",
        nameLink: "",
        when: "Oct. 8-9",
        location: "Hartland, WI",
        result: "-"
        },
        {
        season: "Fall",
        nameLabel: "Missouri Loves Company",
        nameLink: "https://scorereport.net/2022/college-open/event16827",
        when: "Nov. 12-13",
        location: "Columbia, MO",
        result: "-"
        },
        {
        season: "Spring",
        nameLabel: "Florida Warmup",
        nameLink: "https://play.usaultimate.org/events/Florida-Warm-Up-2023/",
        when: "February 3-5",
        location: "Tampa, FL",
        result: "18T"
        },
        {
        season: "Spring",
        nameLabel: "Centex",
        nameLink: "https://play.usaultimate.org/events/Centex-2023/",
        when: "March 17-19",
        location: "Austin, TX",
        result: "16th",
        },
        {
        season: "Spring",
        nameLabel: "Illinois Conferences",
        nameLink: "https://play.usaultimate.org/events/Illinois-D-I-College-Mens-CC-2023/",
        when: "April 15-16",
        location: "Rantoul, IL",
        result: "2nd",
        },
        {
        season: "Spring",
        nameLabel: "Great Lakes D-I College Men's Regionals",
        nameLink: "https://play.usaultimate.org/events/Great-Lakes-D-I-College-Mens-Regionals-2023/",
        when: "May 6-7",
        location: "Holland, MI",
        result: "3rd",
        }
    ]
  },
  {
    year: 2022,
    tournaments: [
      {
        season: "Spring",
        nameLabel: "Missouri Loves Company",
        nameLink: "https://play.usaultimate.org/events/Missouri-Loves-Company-MLC/",
        when: "March 18-20",
        location: "Boonville, MO",
        result: "-"
      },
      {
        season: "Spring",
        nameLabel: "Easterns",
        nameLink: "https://play.usaultimate.org/events/Easterns-2022/",
        when: "April 2-3",
        location: "SC",
        result: "14th",
      },
      {
        season: "Spring",
        nameLabel: "Illinois Conferences",
        nameLink: "https://play.usaultimate.org/events/Illinois-D-I-College-Mens-CC-2022/",
        when: "April 23-24",
        location: "Rantoul, IL",
        result: "1st",
      },
      {
        season: "Spring",
        nameLabel: "Great Lakes D-I College Men's Regionals",
        nameLink: "https://play.usaultimate.org/events/Great-Lakes-D-I-College-Mens-Regionals-2022/",
        when: "Apr. 30 - Mar. 1",
        location: "Fort Wayne, IN",
        result: "5th",
      }
    ]
  },
  {
    year: 2021,
    tournaments: [
      {
        season: "Fall",
        nameLabel: "Stanley Cup",
        nameLink: "",
        when: "Sept. 25-26",
        location: "Rantoul, IL",
        result: "-"
      },
      {
        season: "Fall",
        nameLabel: "Illinois Conferences",
        nameLink:
          "https://play.usaultimate.org/events/Illinois-D-I-College-Mens-CC-2021/",
        when: "Oct. 23-24",
        location: "Rantoul, IL",
        result: "2nd",
      },
      {
        season: "Fall",
        nameLabel: "Great Lakes Regionals",
        nameLink: "https://play.usaultimate.org/events/Great-Lakes-D-I-College-Mens-Regionals-2021/",
        when: "Nov. 6-7",
        location: "Holland, MI",
        result: "2nd"
      },
      {
        season: "Fall",
        nameLabel: "D-I National Championships",
        nameLink: "https://play.usaultimate.org/events/USA-Ultimate-College-Championships-2021/",
        when: "Dec. 17-20",
        location: "Norco, CA",
        result: "17th"
      }
     

    ],
  },
  {
    year: 2020,
    tournaments: [
      {
        season: "Fall",
        nameLabel: "Boiler Breakaway",
        nameLink:
          "https://docs.google.com/spreadsheets/d/1rcJneU3nzVusTHk0ZPfUZUiotz-OaKLq5NNANzee7hc/edit#gid=955795591",
        when: "Sept. 14th-15th",
        location: "Fair Oaks, IN",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "No Wisconsequences",
        nameLink: "https://scorereport.net/2019/mixed/event16413",
        when: "Oct. 5th-6th",
        location: "Hartland, WI",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Missouri Loves Company",
        nameLink: "",
        when: "Nov. 2nd-3rd",
        location: "Hartland, WI",
        result: "5th",
      },
      {
        season: "Spring",
        nameLabel: "T-Town Throwdown",
        nameLink:
          "https://play.usaultimate.org/events/TTown-Throwdown-2020-Open-/schedule/Men/CollegeMen/",
        when: "Jan. 8th-9th",
        location: "Tuscaloosa, AL",
        result: "1st",
      },
      {
        season: "Spring",
        nameLabel: `Presidents' Day Invite`,
        nameLink:
          "https://play.usaultimate.org/events/Presidents-Day-Invite-2020/schedule/Men/CollegeMen/",
        when: "Feb. 15th-17th",
        location: "San Diego, CA",
        result: "16th",
      },
    ],
  },
  {
    year: 2019,
    tournaments: [
      {
        season: "Fall",
        nameLabel: "Stanley Cup",
        nameLink: "",
        when: "Sept. 15th-16th",
        location: "Normal, IL",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "No Wisconsequences",
        nameLink: "http://nowisconsequences.ultimatecentral.com/",
        when: "Oct. 6th-7th",
        location: "Hartland, WI",
        result: "Cancelled",
      },
      {
        season: "Fall",
        nameLabel: "Missouri Loves Company",
        nameLink:
          "https://www.facebook.com/MissouriLovesCompany2018/?__tn__=kC-R&eid=ARD-KglibzvntFiwk2JIDOD7ylzeCvYMbvSB_99_VM1USVNvHhUI-xbx9_5daViGshubp48EAO_JeeQN&hc_ref=ARTJXZoaRgrfp1S7ZG7wFELrx16ZzqCQFcVGc0QTgJRTXYDiQzO5vvz7C7w98xlGwlQ&fref=nf&__xts__[0]=68.ARCyulKkGdiLaL5RY_ewBJJWmIkPdXmYjylVbU1evhOA8aoDo2_4d1-gUj0K4XicIY-HFgNGrqckgixrUWF8wLlYLEOEbjGJELrPVLQJWNQd-6jNksBooiRrMXDGPJluVge-2PBdUQilJrrLatP_ZcJhVYl551eFsop8NRJbIFTCqIPsdaKbj8Ggei4fvK4bZCKmz713zkUebAyg0wossHhbsRjhfQOvXK5Qw4K01SqL_OOKS6TWXAiCNgo5lPfjYvXyv8DOJZu1yfJxqBqXGltD5gJ01APAoEbn9MWANl4VPubpnCJTyYsDC04AC4sBWgpgtKsWCCkuNSVAVU0",
        when: "November 3rd-4th",
        location: "Columbia, MO",
        result: "-",
      },
      {
        season: "Spring",
        nameLabel: "T-Town Throwdown",
        nameLink: "https://play.usaultimate.org/events/T-Town-Throwdown/",
        when: "Jan. 26th-27th",
        location: "Tuscaloosa, AL",
        result: "3rd",
      },
      {
        season: "Spring",
        nameLabel: `Presidents' Day Invite`,
        nameLink:
          "https://play.usaultimate.org/events/Presidents-Day-Invite-2019/",
        when: "Feb. 16th-18th",
        location: "San Diego, CA",
        result: "10th",
      },
      {
        season: "Spring",
        nameLabel: "Centex",
        nameLink: "https://play.usaultimate.org/events/Centex-2019-Men-/",
        when: "March 16th-17th",
        location: "Austin, TX",
        result: "9th",
      },
      {
        season: "Spring",
        nameLabel: "Huck Finn",
        nameLink: "https://play.usaultimate.org/events/Huck-Finn-XXIII/",
        when: "March 30th-31st",
        location: "St. Charles, MO",
        result: "2nd",
      },
      {
        season: "Spring",
        nameLabel: "Illinois Conferences",
        nameLink:
          "https://play.usaultimate.org/events/Illinois-D-I-College-Mens-CC-2019/",
        when: "April 13th-14th",
        location: "Rantoul, IL",
        result: "T-2nd*",
      },
      {
        season: "Spring",
        nameLabel: "Great Lakes Regionals",
        nameLink:
          "https://play.usaultimate.org/events/Great-Lakes-D-I-College-Mens-Regionals-2019/",
        when: "April 27th-28th",
        location: "Joliet, IL",
        result: "3rd",
      },
    ],
  },
  {
    year: 2018,
    tournaments: [
      {
        season: "Fall",
        nameLabel: "Boiler Breakaway",
        nameLink: "https://boilerlink.purdue.edu/event/972920",
        when: "Sept. 16th-17th",
        location: "West Lafayette, IN",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "No Wisconsequences",
        nameLink: "http://nowisconsequences.ultimatecentral.com",
        when: "Oct. 7th-8th",
        location: "Hartland, WI",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Missouri Loves Company",
        nameLink: "http://ultimate.missouri.edu/mlc/2017/",
        when: "Nov. 4th-5th",
        location: "Columbia, MO",
        result: "-",
      },
      {
        season: "Spring",
        nameLabel: "Mid-Atlantic Warmup",
        nameLink:
          "https://play.usaultimate.org/events/Mid-Atlantic-Warmup-2018/",
        when: "Feb. 3rd-4th",
        location: "Williamsburg, VA",
        result: "1st",
      },
      {
        season: "Spring",
        nameLabel: `Presidents' Day Invite`,
        nameLink:
          "https://play.usaultimate.org/events/Presidents-Day-Invitational-Tournament-2018/",
        when: "Feb. 17th-19th",
        location: "San Diego, CA",
        result: "9th",
      },
      {
        season: "Spring",
        nameLabel: "Centex",
        nameLink: "https://play.usaultimate.org/events/Mens-Centex-2018/",
        when: "March 10th-11th",
        location: "Austin, TX",
        result: "6th",
      },
      {
        season: "Spring",
        nameLabel: "Huck Finn",
        nameLink: "https://play.usaultimate.org/events/Huck-Finn-2018/",
        when: "March 31st-April 1st",
        location: "St. Louis, MO",
        result: "13th",
      },
      {
        season: "Spring",
        nameLabel: "Illinois Conferences",
        nameLink:
          "https://play.usaultimate.org/events/Illinois-D-I-College-Mens-CC-2018/",
        when: "April 14th-15th",
        location: "Rantoul, IL",
        result: "2nd",
      },
      {
        season: "Spring",
        nameLabel: "Great Lakes Regionals",
        nameLink:
          "https://play.usaultimate.org/events/Great-Lakes-D-I-College-Mens-Regionals-2018/",
        when: "April 28th-29th",
        location: "Brighton, MI",
        result: "T-3rd",
      },
    ],
  },
  {
    year: 2017,
    tournaments: [
      {
        season: "Fall",
        nameLabel: "Boiler Breakaway",
        nameLink:
          "https://boilerlink.purdue.edu/organization/mensultimate/calendar/details/972920",
        when: "Sept. 17th-18th",
        location: "West Lafayette, IN",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "No Wisconsequences",
        nameLink: "http://nowisconsequences.ultimatecentral.com",
        when: "Oct. 8th-9th",
        location: "Hartland, WI",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Missouri Loves Company",
        nameLink: "http://ultimate.missouri.edu/mlc/2016",
        when: "Nov. 5th-6th",
        location: "Columbia, MO",
        result: "-",
      },
      {
        season: "Spring",
        nameLabel: `Presidents' Day Invite`,
        nameLink:
          "http://play.usaultimate.org/events/Presidents-Day-Tournament-2017",
        when: "Feb. 17th-20th",
        location: "San Diego, CA",
        result: "5th",
      },
      {
        season: "Spring",
        nameLabel: "Centex",
        nameLink: "http://play.usaultimate.org/events/Centex-2017-Open",
        when: "March 10th-13th",
        location: "Austin, TX",
        result: "7th",
      },
      {
        season: "Spring",
        nameLabel: "Huck Finn",
        nameLink: "http://play.usaultimate.org/events/Huck-Finn-2017",
        when: "April 1st-2nd",
        location: "St. Louis, MO",
        result: "11th",
      },
      {
        season: "Spring",
        nameLabel: "Illinois Conferences",
        nameLink:
          "http://play.usaultimate.org/events/Illinois-D-I-College-Mens-CC-2017",
        when: "April 22nd-23rd",
        location: "Rantoul, IL",
        result: "2nd",
      },
      {
        season: "Spring",
        nameLabel: "Great Lakes Regionals",
        nameLink:
          "http://play.usaultimate.org/events/Great-Lakes-D-I-College-Mens-Regionals-2017",
        when: "May 6th-7th",
        location: "Fair Oaks, IN",
        result: "6th",
      },
    ],
  },
  {
    year: 2016,
    tournaments: [
      {
        season: "Fall",
        nameLabel: "Boiler Breakaway",
        nameLink:
          "https://www.reddit.com/r/ultimate/comments/38gy72/new_fields_boiler_breakaway_2015_hosted_by_undue",
        when: "Sept. 19th-20th",
        location: "Westfield, IN",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Huck of the Irish",
        nameLink: "https://ndultimate.wordpress.com/hoti",
        when: "Oct. 3rd-4th",
        location: "South Bend, IN",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Chicago Invite",
        nameLink: "http://www.chicagoinvite.com",
        when: "Oct. 17th-18th",
        location: "Rockford, IL",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Missouri Loves Company",
        nameLink:
          "http://ultiworld.com/2015/11/12/missouri-loves-company-2015-tournament-recap-mens",
        when: "Nov. 7th-8th",
        location: "Columbia, MO",
        result: "10th",
      },
      {
        season: "Spring",
        nameLabel: "Florida Warmup",
        nameLink:
          "http://play.usaultimate.org/events/Warm-up-A-Florida-Affair-2016",
        when: "Feb. 11th-14th",
        location: "Tampa, FL",
        result: "15th",
      },
      {
        season: "Spring",
        nameLabel: "Easterns",
        nameLink: "http://play.usaultimate.org/events/College-Easterns-2016",
        when: "March 18th-20th",
        location: "North Myrtle Beach, SC",
        result: "19th",
      },
      {
        season: "Spring",
        nameLabel: "Huck Finn",
        nameLink: "http://play.usaultimate.org/events/Huck-Finn-XX-2016",
        when: "Feb. 11th-14th",
        location: "St. Louis, MO",
        result: "11th",
      },
      {
        season: "Spring",
        nameLabel: "Illinois Conferences",
        nameLink:
          "http://play.usaultimate.org/events/Illinois-D-I-College-Mens-CC-2016",
        when: "April 16th-17th",
        location: "Rantoul, IL",
        result: "3rd",
      },
      {
        season: "Spring",
        nameLabel: "Great Lakes Regionals",
        nameLink:
          "http://play.usaultimate.org/events/Great-Lakes-D-I-College-Mens-Regionals-2016",
        when: "April 30th-May 1st",
        location: "Fair Oaks, IN",
        result: "3rd",
      },
    ],
  },
  {
    year: 2015,
    tournaments: [
      {
        season: "Fall",
        nameLabel: "Huck of the Irish",
        nameLink: "https://ndultimate.wordpress.com/whitesmoke",
        when: "Sept. 27th-28th",
        location: "South Bend, IN",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Glory Days",
        nameLink: "http://www.sun-ultimate.org/glory-days.html",
        when: "Oct. 4th-5th",
        location: "Rockford, IL",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Illinois Invite",
        nameLink:
          "https://illinois.collegiatelink.net/organization/illinoismensultimatefrisbeeteam/calendar/details/504204",
        when: "Oct. 18th-19th",
        location: "Rantoul, IL",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Missouri Loves Company",
        nameLink:
          "http://ultiworld.com/2014/11/06/missouri-loves-company-2014-tournament-preview-mens",
        when: "Nov. 8th-9th",
        location: "Columbia, MO",
        result: "-",
      },
      {
        season: "Spring",
        nameLabel: "Florida Warmup",
        nameLink:
          "http://play.usaultimate.org/events/Warm-Up-A-Florida-Affair-2015",
        when: "Feb. 13th-15th",
        location: "Tampa, FL",
        result: "13th",
      },
      {
        season: "Spring",
        nameLabel: "Centex Invite",
        nameLink: "http://play.usaultimate.org/events/Centex-Invite-2015",
        when: "March 14th-15th",
        location: "Austin, TX",
        result: "17th",
      },
      {
        season: "Spring",
        nameLabel: "Easterns",
        nameLink: "http://play.usaultimate.org/events/Easterns-2015",
        when: "March 27th-29th",
        location: "North Myrtle Beach, SC",
        result: "17th",
      },
      {
        season: "Spring",
        nameLabel: "Illinois Conferences",
        nameLink:
          "http://play.usaultimate.org/events/Illinois-D-I-College-Mens-CC-2015",
        when: "April 11th-12th",
        location: "Rantoul, IL",
        result: "2nd",
      },
      {
        season: "Spring",
        nameLabel: "Great Lakes Regionals",
        nameLink:
          "http://play.usaultimate.org/events/Great-Lakes-D-I-College-Mens-Regionals-2015",
        when: "April 25th-26th",
        location: "Rockford, IL",
        result: "1st",
      },
      {
        season: "Spring",
        nameLabel: "D-I National Championships",
        nameLink:
          "http://play.usaultimate.org/events/USA-Ultimate-D-I-College-Championships-2015",
        when: "May 22nd-25th",
        location: "Milwaukee, WI",
        result: "13th",
      },
    ],
  },
  {
    year: 2014,
    tournaments: [
      {
        season: "Fall",
        nameLabel: "Boiler Breakaway",
        nameLink:
          "http://scores.usaultimate.org/scores/#college-open/tournament/13422",
        when: "Oct. 5th-6th",
        location: "West Lafayette, IN",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Illinois Invite",
        nameLink:
          "http://scores.usaultimate.org/scores/#college-open/tournament/13350",
        when: "Oct. 12th-13th",
        location: "Rantoul, IL",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Michigan Round Robin",
        nameLink: "",
        when: "Oct. 20th",
        location: "Ann Arbor, MI",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Glory Days",
        nameLink:
          "http://scores.usaultimate.org/scores/#college-open/tournament/13499",
        when: "Oct. 26th-27th",
        location: "Rockford, IL",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Missouri Loves Company",
        nameLink:
          "http://scores.usaultimate.org/scores/#college-open/tournament/14017",
        when: "Nov. 9th-10th",
        location: "Columbia, MO",
        result: "13th",
      },
      {
        season: "Spring",
        nameLabel: "Cat Fight",
        nameLink: "http://play.usaultimate.org/events/Cat-Fight",
        when: "Feb. 22nd-23rd",
        location: "Lexington, KY",
        result: "1st",
      },
      {
        season: "Spring",
        nameLabel: "Easterns",
        nameLink:
          "http://play.usaultimate.org/events/Easterns-and-DIII-Easterns",
        when: "March 22nd-23rd",
        location: "Little River, SC",
        result: "13th",
      },
      {
        season: "Spring",
        nameLabel: "Illinois Conferences",
        nameLink:
          "http://play.usaultimate.org/events/Illinois-D-I-College-Mens-CC",
        when: "April 12th-13th",
        location: "Rantoul, IL",
        result: "1st",
      },
      {
        season: "Spring",
        nameLabel: "Great Lakes Regionals",
        nameLink:
          "http://play.usaultimate.org/events/Great-Lakes-D-I-College-Mens-Regionals",
        when: "April 26th-27th",
        location: "Rockford, IL",
        result: "5th",
      },
    ],
  },
  {
    year: 2013,
    tournaments: [
      {
        season: "Fall",
        nameLabel: "Boiler Breakaway",
        nameLink:
          "http://scores.usaultimate.org/scores2012/#college-open/tournament/11432",
        when: "Sept. 22nd-23rd",
        location: "West Lafayette, IN",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Illinois Invite",
        nameLink:
          "http://scores.usaultimate.org/scores2012/#college-open/tournament/11476",
        when: "Oct. 13th-14th",
        location: "Rantoul, IL",
        result: "-",
      },
      {
        season: "Fall",
        nameLabel: "Missouri Loves Company",
        nameLink:
          "http://scores.usaultimate.org/scores2012/#college-open/tournament/11657",
        when: "Nov. 10th-11th",
        location: "Columbia, MO",
        result: "-",
      },
      {
        season: "Spring",
        nameLabel: "Huck Finn",
        nameLink:
          "http://scores.usaultimate.org/scores/#college-open/tournament/12115",
        when: "March 9th-10th",
        location: "Chesterfield, MO",
        result: "13th",
      },
      {
        season: "Spring",
        nameLabel: "Centex",
        nameLink:
          "http://scores.usaultimate.org/scores/#college-open/tournament/12707",
        when: "March 16th-17th",
        location: "Austin, TX",
        result: "4th",
      },
      {
        season: "Spring",
        nameLabel: "Illinois Conferences",
        nameLink:
          "http://scores.usaultimate.org/scores/#college-open/tournament/12604",
        when: "April 13th-14th",
        location: "Rantoul, IL",
        result: "1st",
      },
      {
        season: "Spring",
        nameLabel: "Great Lakes Regionals",
        nameLink:
          "http://scores.usaultimate.org/scores/#college-open/tournament/12667",
        when: "April 27th-28th",
        location: "Rockford, IL",
        result: "1st",
      },
      {
        season: "Spring",
        nameLabel: "D-I National Championships",
        nameLink:
          "http://scores.usaultimate.org/scores/#college-open/tournament/12686",
        when: "May 24th-27th",
        location: "Madison, WI",
        result: "17th",
      },
    ],
  },
];
