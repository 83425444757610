import { Result } from "./results.model";

export const RESULTS_DATA: Result[] = [
  {
    year: 2023,
    sectionals: "2nd",
    regionals: "3rd",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink: "https://play.usaultimate.org/teams/events/Eventteam/?TeamId=SELu%2bMZ7U7jB635kDtCHIbyklpQxruXMS%2fdTuLrV8Ng%3d"
  },
  {
    year: 2022,
    sectionals: "1st",
    regionals: "5th",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink: "https://play.usaultimate.org/events/teams/?EventTeamId=Q8K6zZ5JB2sadh6L8Db1f0VGILlJgEsALdWTRqvRgr8%3d"
  },
  {
    year: 2021,
    sectionals: "2nd",
    regionals: "2nd",
    nationals: "T-17th",
    teamPageLabel: "USAU",
    teamPageLink:
      "https://play.usaultimate.org/teams/events/Eventteam/?TeamId=%2b1VYkWBgGsaliepv6mKRZGz0Vl0rSVDaK6K4Mw7pTT4%3d",
  },
  {
    year: 2020,
    sectionals: "Cancelled due to COVID-19",
    regionals: "Cancelled due to COVID-19",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink:
      "https://play.usaultimate.org/teams/events/Eventteam/?TeamId=hQloGOP7MFirTclgXCafv5m72oDI1pZ50Mh4aYY8Pdk%3d",
  },
  {
    year: 2019,
    sectionals: "T-2nd",
    regionals: "3rd",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink:
      "https://play.usaultimate.org/teams/events/Eventteam/?TeamId=wZoyiwvCg1VhUIWNi1WDaBXsR6kdOBEmTJ6iynmvlbc%3d",
  },
  {
    year: 2018,
    sectionals: "2nd",
    regionals: "T-5th",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink:
      "https://play.usaultimate.org/teams/events/Eventteam/?TeamId=8KdMUyGieTTE%2f5I3oGTamGGWX6SQ2mVHHrWtpy%2fdJkk%3d",
  },
  {
    year: 2017,
    sectionals: "2nd",
    regionals: "6th",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://play.usaultimate.org/events/teams/?EventTeamId=TIUxXrZ4pDNhNi4M3QA1J%2b9D8kAdNss8Ny4JyUUuZhg%3d",
  },
  {
    year: 2016,
    sectionals: "3rd",
    regionals: "3rd",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://play.usaultimate.org/teams/events/Eventteam/?TeamId=nQzsCO0fKbUUEFZfKJ%2fOUotd%2f1PM6wo35HPaXJEMUC8%3d",
  },
  {
    year: 2015,
    sectionals: "2nd",
    regionals: "1st",
    nationals: "13th",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://play.usaultimate.org/teams/events/Eventteam/?TeamId=T%2bV6D2XyLTlkLA%2fX2WpaiPGnYy6S3Dn%2fmcblfo08DNo%3d",
  },
  {
    year: 2014,
    sectionals: "1st",
    regionals: "5th",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://play.usaultimate.org/teams/events/Eventteam/?TeamId=1z8hrygGYaDwJqk8F%2fmP4lOk9BD3%2fI%2bKW33IHo8qF9E%3d",
  },
  {
    year: 2013,
    sectionals: "1st",
    regionals: "1st",
    nationals: "17th",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://scores.usaultimate.org/scores/#college-open/team/1794",
  },
  {
    year: 2012,
    sectionals: "1st",
    regionals: "3rd",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://scores.usaultimate.org/scores2012/#college-open/team/1794",
  },
  {
    year: 2011,
    sectionals: "1st",
    regionals: "1st",
    nationals: "17th",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://scores.usaultimate.org/scores2011/#college-open/team/1794",
  },
  {
    year: 2010,
    sectionals: "1st",
    regionals: "1st",
    nationals: "17th",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://scores.usaultimate.org/scores2010/#college-open/team/1794",
  },
  {
    year: 2009,
    sectionals: "2nd",
    regionals: "1st",
    nationals: "13th",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://ultimate.scorereport.net/2009/scores.cgi?div=18&page=3&team=1794",
  },
  {
    year: 2008,
    sectionals: "1st",
    regionals: "2nd",
    nationals: "5th",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://ultimate.scorereport.net/2008/scores.cgi?div=18&page=3&team=1794",
  },
  {
    year: 2007,
    sectionals: "2st",
    regionals: "4th",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://ultimate.scorereport.net/2007/scores.cgi?div=18&page=3&team=1794",
  },
  {
    year: 2006,
    sectionals: "3rd",
    regionals: "7th",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://ultimate.scorereport.net/2006/scores.cgi?div=18&page=3&team=1794",
  },
  {
    year: 2005,
    sectionals: "2st",
    regionals: "3rd",
    nationals: "-",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://ultimate.scorereport.net/2005/scores.cgi?div=18&page=3&team=1794",
  },
  {
    year: 2004,
    sectionals: "1st",
    regionals: "2nd",
    nationals: "17th",
    teamPageLabel: "USAU",
    teamPageLink:
      "http://ultimate.scorereport.net/2004/scores.cgi?div=18&page=3&team=1794",
  },
];
