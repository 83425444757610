import { Practice } from "./practice.model";

export const PRACTICE_DATA: Practice[] = [
  {
    season: "Fall",
    what: "Normal Practice",
    time: "4:30PM-7PM",
    when: "Mondays & Wednesdays",
    locationLabel: "Complex Fields",
    locationLink:
      "https://www.google.com/maps/place/Florida+and+Lincoln+Playing+Field/@40.0966964,-88.2242806,16z/data=!4m5!1m2!2m1!1scomplex+fields!3m1!1s0x0000000000000000:0x13633840896e25bf",
  },
  //{
  //season: 'Fall',
  //what: 'Weekend Invite Practice',
  //time: 'TBD',
  //when: 'Weekends',
  //locationLabel: 'Complex Fields',
  //locationLink:
  //'https://www.google.com/maps/place/Florida+and+Lincoln+Playing+Field/@40.0966964,-88.2242806,16z/data=!4m5!1m2!2m1!1scomplex+fields!3m1!1s0x0000000000000000:0x13633840896e25bf'
  //},
  {
    season: "Winter",
    what: "Normal Practice",
    time: "9:30PM-12AM",
    when: "Mondays & Wednesdays",
    locationLabel: "Armory",
    locationLink:
      "https://www.google.com/maps/place/Armory/@40.105402,-88.235534,17z/data=!3m1!5s0x880cd73b8c4fbb7d:0xa84890110afd0990!4m7!1m4!3m3!1s0x880cd73a16c1d78f:0xe1bb009225fc1225!2sE+Armory+Ave,+Champaign,+IL+61820!3b1!3m1!1s0x0000000000000000:0x0b21d56f2b951978",
  },
  {
    season: "Winter",
    what: "Indoor Field Practice",
    time: "9PM-11PM",
    when: "Thursdays",
    locationLabel: "Irwin Facility",
    locationLink:
      "https://www.google.com/maps/place/Irwin+Indoor+Football+Facility,+University+of+Illinois+at+Urbana-Champaign,+Champaign,+IL+61820/@40.1006475,-88.2342204,17z/data=!3m1!4b1!4m6!1m3!3m2!1s0x880cd724a92655e9:0xa53c0525046826fa!2sUniversity+of+Illinois+at+Urbana-Champaign!3m1!1s0x880cd725292c02bd:0x18f5a03daad8677a",
  },
  {
    season: "Spring",
    what: "Normal Practice",
    time: "4:30PM-7PM",
    when: "Mondays & Wednesdays",
    locationLabel: "Complex Fields",
    locationLink:
      "https://www.google.com/maps/place/Florida+and+Lincoln+Playing+Field/@40.0966964,-88.2242806,16z/data=!4m5!1m2!2m1!1scomplex+fields!3m1!1s0x0000000000000000:0x13633840896e25bf",
  },
  {
    season: "Spring",
    what: "Normal Practice",
    time: "11AM-1PM",
    when: "Non-Tournament Weekends",
    locationLabel: "Complex Fields",
    locationLink:
      "https://www.google.com/maps/place/Florida+and+Lincoln+Playing+Field/@40.0966964,-88.2242806,16z/data=!4m5!1m2!2m1!1scomplex+fields!3m1!1s0x0000000000000000:0x13633840896e25bf",
  },
  // {
  //   season: "",
  //   what: "",
  //   time: "While unlikely, times subject to change based on field availability",
  //   when: "",
  //   locationLabel: "",
  //   locationLink: "",
  // },
];
