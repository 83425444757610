export const ABOUT_US = "aboutus";
export const ABSENT_FORM = "absentform";
export const AWARDS = "awards";
export const CONTACT_US = "contactus";
export const HOME = "home";
export const PAST_RESULTS = "pastresults";
export const PRACTICE = "practice";
export const PROSPECTIVE_PLAYERS = "prospective";
export const ROSTER = "roster";
export const TEAM_HISTORY = "teamhistory";
export const TOURNAMENTS = "tournaments";
